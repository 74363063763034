/* eslint-disable camelcase */
import { handleResponse } from '../../helpers';

export const getProperties = ({
  id,
  typeProperty,
  departmentId,
  cityId,
  query,
  businessKind,
  page,
  userOwner,
}) => {
  const { bedrooms, maxPrice, minPrice, maxArea, minArea, description } = query;

  const { smls, my_net } = userOwner;

  let bedRooms = '';

  if (bedrooms) {
    bedRooms = Object.keys(bedrooms).reduce((accumulator, check, index) => {
      let newAccumulator = accumulator;
      if (bedrooms[check]) {
        newAccumulator += `${index + 1},`;
      }

      return newAccumulator;
    }, '');
  }

  if (bedRooms !== '') {
    bedRooms = `&bedrooms=${bedRooms.slice(0, -1)}`;
  }

  const priceMax = maxPrice && maxPrice !== '0' ? `&maxPrice=${maxPrice}` : '';
  const priceMin = minPrice && minPrice !== '0' ? `&minPrice=${minPrice}` : '';
  const areaMax = maxArea && maxArea !== '0' ? `&maxArea=${maxArea}` : '';
  const areaMin = minArea && minArea !== '0' ? `&minArea=${minArea}` : '';
  const typeProperties =
    typeProperty && typeProperty !== '0' ? `&typeProperty=${typeProperty}` : '';
  const department =
    departmentId && departmentId !== '0' ? `&departmentId=${departmentId}` : '';
  const city = cityId && cityId !== '0' ? `&cityId=${cityId}` : '';
  const descriptionProperty = description ? `&description=${description}` : '';
  const netMls = `&smls=${smls}&my_net=${my_net}`;
  const kindBusiness =
    businessKind && businessKind !== '0' ? `&businessKind=${businessKind}` : '';
  const pageQuery = `&page=${page}&page_size=20`;
  const queryFinal = `?${typeProperties}${department}${city}${areaMin}${areaMax}${priceMin}${priceMax}${bedRooms}${kindBusiness}${pageQuery}${descriptionProperty}${netMls}`;

  const requestOptions = {
    method: 'GET',
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/user-properties/${id}/${
      queryFinal !== '?' ? queryFinal : ''
    }`,
    requestOptions
  ).then(handleResponse);
};

export const getPropertyByid = ({ id }) => {
  const requestOptions = {
    method: 'GET',
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/property/${id}`,
    requestOptions
  ).then(handleResponse);
};

export const sendInformationCustomer = (body, name, property, user) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(body),
  };

  console.log(body);

  return fetch(`${process.env.REACT_APP_API_URL}/mail`, requestOptions)
    .then(() => {
      const wpMsg = `?phone=${user?.user?.phone}&text=Hola soy ${name}! Acabo de ver este inmueble y deseo mas información ${window.location.host}/${property.user.id}/${property.id}`;
      if (window.screen.availWidth <= 768) {
        window.location.replace(`https://api.whatsapp.com/send/${wpMsg}`);
      } else {
        window.location.replace(`https://web.whatsapp.com/send/${wpMsg}`);
      }
    })
    .catch((error) => console.error(error));
};
